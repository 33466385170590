<template>
    <div>
        

        <div class="content content_top_margin" style="min-height: 175px;">
            <div class="content_inner  ">
                <div class="full_width ">
                    <div class="full_width_inner">

                        <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636151250857 grid_section"
                            style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  single_top_row"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <h2>Working in group settings</h2>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <p>
                                                                                <router-link :to="{ name: 'meeting-rooms-a45' }">
                                                                                    <em>Up
                                                                                    Next: </em> Meeting Rooms <img
                                                                                    class="vc_single_image-img attachment-full"
                                                                                    src="../../assets/images/airbnb/Airbnb-Arrows-2.png"
                                                                                    alt="" width="64"
                                                                                    height="37">
                                                                                </router-link>
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner "
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-8">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="vc_empty_space" style="height: 12px">
                                                                        <span class="vc_empty_space_inner">
                                                                            <span class="empty_space_image"></span>
                                                                        </span></div>


                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <h3 class="info-popup-wrapper">Ergo anywhere
                                                                                <span class="info-popup"><img
                                                                                        src="../../assets/images/airbnb/info-popup.png"
                                                                                        alt="Hover for info"
                                                                                        width="35"><em>Read through some
                                                                                        tips to increase your comfort
                                                                                        while working in group settings.
                                                                                        Click on an image to learn
                                                                                        more!</em></span></h3>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner "
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-8">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left   rounded">
                                                                        <div class="wpb_wrapper">

                                                                            <div
                                                                                class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                <img width="700" height="400"
                                                                                    src="../../assets/images/airbnb/3rd-Floor-SBM_SF-WFG-Airbnb-Ergo-Anywhere-smallest-e1638396496989.jpg"
                                                                                    class="vc_single_image-img attachment-full"
                                                                                    alt="" loading="lazy"
                                                                                    title="3rd-Floor-SBM_SF - WFG Airbnb Ergo Anywhere (smallest)"
                                                                                    >
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper stng_bold">
                                                                            <p>No matter where you and your team meet,
                                                                                you can easily set yourself up to work
                                                                                comfortably.</p>
                                                                            <p><strong> Keyboard and mouse</strong> –
                                                                                Your keyboard and mouse should be at, or
                                                                                slightly below, your elbow height.
                                                                                Elbows should stay under your shoulders.
                                                                            </p>
                                                                            <p><strong>Screens</strong> – Set your
                                                                                laptop screen to be about an arm’s
                                                                                distance away and slightly below eye
                                                                                level.</p>
                                                                            <p><strong>Sit or stand</strong> – Remember
                                                                                to switch it up! Alternate between
                                                                                sitting and standing throughout your
                                                                                work day.</p>

                                                                        </div>
                                                                    </div>
                                                                    <div class="vc_empty_space" style="height: 32px">
                                                                        <span class="vc_empty_space_inner">
                                                                            <span class="empty_space_image"></span>
                                                                        </span></div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="no-margin wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_text_column wpb_content_element  vc_custom_1637627221162">
                                                                        <div class="wpb_wrapper">
                                                                            <h2>Quick Setup</h2>
                                                                            <p>&nbsp;</p>
                                                                            <ul>
                                                                                <li>
                                                                                    <h3>Prop up your laptop screen to
                                                                                        eye level.</h3>
                                                                                    <p>&nbsp;</p>
                                                                                </li>
                                                                            </ul>
                                                                            <h3></h3>
                                                                            <h3></h3>
                                                                            <ul>
                                                                                <li>
                                                                                    <h3>Use an external keyboard and
                                                                                        mouse.</h3>
                                                                                    <p>&nbsp;</p>
                                                                                </li>
                                                                            </ul>
                                                                            <h3></h3>
                                                                            <h3></h3>
                                                                            <ul>
                                                                                <li>
                                                                                    <h3>You can also use a lap desk to
                                                                                        type and use a mouse in your
                                                                                        lap.</h3>
                                                                                    <p>&nbsp;</p>
                                                                                </li>
                                                                            </ul>
                                                                            <h3></h3>
                                                                            <h3></h3>
                                                                            <ul>
                                                                                <li>
                                                                                    <h3>Try carrying a small inflatable
                                                                                        pillow for instant back support.
                                                                                    </h3>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vc_row wpb_row section vc_row-fluid  grid_section" style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <h2>You may also like</h2>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  continue_block"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                             <router-link :to="{ name: 'working-from-the-cafe-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="2560" height="1002"
                                                                                        src="../../assets/images/airbnb/REPLACEMENT-WORK-IN-CAFE-PARK-ETC2-scaled.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="REPLACEMENT - WORK IN CAFE, PARK ETC2"
                                                                                        >
                                                                                </div>
                                                                             </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'working-from-the-cafe-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Working from the cafe</h4>
                                                                            <p>Working from the cafe? Ergo has your back
                                                                                while you get caffeinated.</p>

                                                                        </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                         <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                             <router-link :to="{ name: 'working-from-listings-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="632" height="422"
                                                                                        src="../../assets/images/airbnb/Airbnb-landing-Page-Working-From-Listing-1.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-landing-Page---Working-From-Listing"
                                                                                        >
                                                                                </div>
                                                                             </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                         <router-link :to="{ name: 'working-from-listings-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Working from an Airbnb</h4>
                                                                            <p>Discover how to stay on your ergo game
                                                                                wherever you are working.</p>

                                                                        </div>
                                                                         </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                             <router-link :to="{ name: 'working-on-the-move-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="1008" height="643"
                                                                                        src="../../assets/images/airbnb/Airbnb-Landing-Page-On-the-Move.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="3rd-Floor-SBM_SF - WFG Airbnb Ergo Anywhere (sm)"
                                                                                        >
                                                                                </div>
                                                                             </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                         <router-link :to="{ name: 'working-on-the-move-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Working on the move</h4>
                                                                            <p>Wanderlust catches us all. Try these tips to bring ergo anywhere.</p>

                                                                        </div>
                                                                         </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               

            </div>
        </div>
    </div>
</template>
<script>
    //import carousel from "vue-owl-carousel"
    export default {
        name: 'working-in-groups-a45',
        mounted(){
			document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
		},
    }
</script>